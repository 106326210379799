import React from "react"
import { navigate } from "gatsby-link";

function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

export default class ContactForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleSubmit = e => {
        e.preventDefault();
        const form = e.target;
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            "form-name": form.getAttribute("name"),
            ...this.state
          })
        })
          .then(() => navigate(form.getAttribute("action")))
          .catch(error => alert(error));
    }

    render() {
        return (
            <form 
            className={this.props.className}
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            action="/success/"
            onSubmit={this.handleSubmit}>
                <h1>Send us a message</h1>

                <input type="hidden" name="bot-field" onChange={this.handleChange}/>
                <input type="hidden" name="form-name" value="contact"/>

                <label>Name</label>   
                <input type="text" name="name" onChange={this.handleChange}/>

                <label>Email</label>
                <input type="email" name="email" onChange={this.handleChange}/>

                <label>Message</label>
                <textarea name="message" onChange={this.handleChange}></textarea>
                
                <button type="submit">Send</button>
            </form>
        )
    }
}