
import React from "react"
import styled from "styled-components"

import Layout from "../layouts/layout"
import ImgBackground from "../components/imgBackground"
import ContactForm from "../components/contactForm"

export default ({ data }) => (    
    <Layout>
        <FullPageDiv>
            <ImgBackground/>
            <Form/>
        </FullPageDiv>
    </Layout>
)

const FullPageDiv = styled.div`
    height: 100vh;

    overflow: hidden;
`

const Form = styled(ContactForm)`
    width: 100%;
    height: 100%;

    padding: 3em 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;

    label {
        margin: 0.5em;
    }

    input {
        width: 50%;

        text-align: center;

        opacity: 0.5;
    }

    textarea {
        width: 75%;
        height: 8em;

        opacity: 0.5;

        resize: none;
    }

    button {
        margin: 1em;
        padding: 0.5em 3em;

        border: 3px solid white; 
        background-color: transparent;
        color: white;
        opacity: 0.75;

        transition: opacity 200ms ease-in-out;

        :hover {
            opacity: 1.0;

        }
    }
`